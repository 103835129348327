import axios from 'axios';
import router from '../router/index.js'

// axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
// axios.defaults.baseURL = 'http://47.92.108.215:8080';
axios.defaults.baseURL = 'https://report-tools.morefun.me';
const api = {
  get(url, param) {
    return new Promise((resolve, reject) => {
      axios.get(url, { params: param }).then(response => resolve(response.data)).catch(error => reject(error));
    });
  },

  post(url, data) {
    return new Promise((resolve, reject) => {
      axios.post(url, data).then(response => resolve(response.data)).catch(error => reject(error));
    });
  }
};
axios.interceptors.request.use((config) => {
  // config.headers.Token = getToken();
  if (config.method === 'post') {
    config.headers['Content-Type'] = 'application/json'
    config.data = JSON.stringify(config.data);
  }
  console.log('request:', config);
  return config;
}, (error) => {
  console.log('错误的传参')
  return Promise.reject(error);
});
axios.interceptors.response.use((res) => {
  console.log('response:', res.data);
  if (res.data.code == '40001' || res.data.code == '40000' || res.data.code == '40002') {
    topMsg(res.data.errorMsg);
    router.push({ path: '/temp' });
    return;
  }
  return res;
}, (error) => {
  console.log('错误的响应')
  return Promise.reject(error);
})

// function getToken(){
//     return localStorage.getItem('token');
// }
function topMsg(text) {
  this.$message({
    showClose: true,
    message: text,
    center: true,
    type: 'error'
  })
}

export default api;